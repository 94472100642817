import { SiAirasia, SiAircanada, SiAirchina, SiAirfrance, SiAirindia, SiAirserbia, SiAmericanairlines, SiBritishairways, SiEthiopianairlines, SiEtihadairways, SiIberia, SiJapanairlines, SiKlm, SiLotpolishairlines, SiLufthansa, SiNorwegian, SiPegasusairlines, SiQatarairways, SiRyanair, SiSingaporeairlines, SiTui, SiTurkishairlines, SiUnitedairlines, SiWizzair } from "react-icons/si";
import getAirlineName from "./AirlineNames";

export default function getAirlineLogo(iataCode) {

    switch (iataCode) {

        case "AA":
            return <SiAmericanairlines title="American Airlines" />;
        case "AC":
            return <SiAircanada title="Air Canada" />;
        case "AF":
            return <SiAirfrance title="Air France" />;
        case "AI":
            return <SiAirindia title="Air India" />;
        case "AK":
            return <SiAirasia title="Air Asia" />;
        case "BA":
            return <SiBritishairways title="British Airways" />;
        case "BLX":
            return <SiTui title="TUI" />;
        case "CA":
            return <SiAirchina title="Air China" />;
        case "D8":
            return <SiNorwegian title="Norwegian" className="text-3xl" />;
        case "DY":
            return <SiNorwegian title="Norwegian" className="text-3xl" />;
        case "IB":
            return <SiIberia title="Iberia" />;
        case "JU":
            return <SiAirserbia title="Air Serbia" />;
        case "ET":
            return <SiEthiopianairlines title="Ethiopian Airlines" />;
        case "EY":
            return <SiEtihadairways title="Etihad Airways" />;
        case "FR":
            return <SiRyanair title="Ryanair" />;
        case "JL":
            return <SiJapanairlines title="Japan Airlines" />;
        case "KL":
            return <SiKlm title="KLM" />;
        case "LH":
            return <SiLufthansa title="Lufthansa" />;
        case "LO":
            return <SiLotpolishairlines title="LOT Polish Airlines" />;
        case "PC":
            return <SiPegasusairlines title="Pegasus Airlines" className="text-4xl" />;
        case "QR":
            return <SiQatarairways title="Qatar Airways" />;
        case "SQ":
            return <SiSingaporeairlines title="Singapore Airlines" />;
        case "TK":
            return <SiTurkishairlines title="Turkish Airlines" />;
        case "UA":
            return <SiUnitedairlines title="United Airlines" />;
        case "W6":
            return <SiWizzair title="Wizz Air" className="text-3xl" />;
        default:
            return <div title={getAirlineName(iataCode)}></div>
    }

};