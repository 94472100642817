import React from "react";
import { DateFormatter } from "../../shared/utilities/DateFormatter.js";
import getAirlineLogo from "../../shared/utilities/AirlineLogos.js";
import getAirlineName from "../../shared/utilities/AirlineNames.js";
import IDeparture from "../interfaces/IDeparture.js";

interface IDepartureCardProps {
    data: IDeparture;
}

export default function DepartureCard(props: IDepartureCardProps) {
    const color = (function () {
        switch (props.data.locationAndStatus.flightLegStatus) {
            case "SCH":
                return 'green';
            case "ACT":
                return 'yellow';
            case "LAN":
                return 'green';
            case "CAN":
                return 'red';
            default:
                return <></>;
        }
    })();

    return (
        <div className={`p-1 my-2 border-b-${color}-400 border-b-2 rounded-b-lg text-primary`}>
            <div className="flex justify-between lg:text-2xl">
                <div className="flex items-center w-1/3 gap-1">
                    {DateFormatter.toLocalTimeString(props.data.departureTime.scheduledUtc) ?? "N/A"}
                </div>
                <div className="flex items-center w-1/3 gap-1 justify-center">
                    {props.data.arrivalAirportSwedish}
                </div>
                <div className="flex items-center w-1/3 gap-1 justify-end" title={getAirlineName(props.data.airlineOperator.iata)}>
                    {getAirlineLogo(props.data.airlineOperator.iata)} {props.data.flightId}
                </div>
            </div>
            <div className="flex justify-center text-primary mt-5">
                {props.data.locationAndStatus.flightLegStatusSwedish.toUpperCase()}
            </div>
        </div>
    );
}