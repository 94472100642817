import React from 'react';
import { ButtonPropsVariantOverrides, ColorPaletteProp, Button as MuiButton, VariantProp } from '@mui/joy';
import { OverridableStringUnion } from '@mui/types';

// ...existing code...
export interface ButtonsProp {
    children: React.ReactNode;
    className?: string;
    variant?: OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>;
    fullWidth?: boolean;
    color?: OverridableStringUnion<ColorPaletteProp>;
    size?: 'sm' | 'md' | 'lg';
    onClick?: () => void;
}

export default function Button(props: ButtonsProp) {
    return (
        <MuiButton size={props.size} className={props.className} variant={props.variant} fullWidth={props.fullWidth} color={props.color} onClick={props.onClick}>{props.children}</MuiButton>
    );
}