import IArrival from "../interfaces/IArrival.ts";

export default function byArrivalTime(a: IArrival, b: IArrival) {
    if (a.arrivalTime.scheduledUtc < b.arrivalTime.scheduledUtc) {
        return -1;
    }
    if (a.arrivalTime.scheduledUtc > b.arrivalTime.scheduledUtc) {
        return 1;
    }
    return 0;
}