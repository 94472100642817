import React, { ReactElement } from "react";
import IArrival from "../interfaces/IArrival.ts";
import IDeparture from "../../departures/interfaces/IDeparture.ts";

interface IFlightListProps {
    source: IArrival[] | IDeparture[],
    isLoading: boolean,
    height?: string,
    children: ((flight: IArrival, index: number) => ReactElement) | ((flight: IDeparture, index: number) => ReactElement)
}

export default function FlightList({ source, isLoading, height, children: fn }: IFlightListProps) {
    const hasData = source && source.length > 0;

    if (isLoading)
        return <div className="flex flex-grow items-center justify-center"><p>Laddar...</p></div>

    if (!hasData && !isLoading)
        return <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-500">Inga flygningar hittades</p>
        </div>

    return (
        hasData && !isLoading && <div className="lg:pr-1 overflow-y-auto">
            <ul>
                {
                    source?.map((flight, index) => {
                        return (
                            <li key={index}>
                                {fn(flight, index)}
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}