import IDeparture from "../interfaces/IDeparture.ts";

export default function byDepartureTime(a: IDeparture, b: IDeparture) {
    if (a.departureTime.scheduledUtc < b.departureTime.scheduledUtc) {
        return -1;
    }
    if (a.departureTime.scheduledUtc > b.departureTime.scheduledUtc) {
        return 1;
    }
    return 0;
}