import React, { useEffect, useState } from "react";
import "./App.css";
import useFetchArrivals from "./arrivals/hooks/useFetchArrivals.ts";
import IArrival from "./arrivals/interfaces/IArrival.ts";
import { DateFormatter } from "./shared/utilities/DateFormatter.js";
import Button from "./shared/components/Button_v2.tsx";
import useFetchDepartures from "./arrivals/hooks/useFetchDepartures.tsx";
import IDeparture from "./departures/interfaces/IDeparture.ts";
import { Drawer } from "@mui/joy";
import { MdClose, MdMenu } from "react-icons/md";
import ArrivalCard from "./arrivals/components/ArrivalCard.tsx";
import FlightList from "./arrivals/components/FlightList.tsx";
import DepartureCard from "./departures/presentation/DepartureCard.tsx";
import byArrivalTime from "./arrivals/utilities/sortByArrivalTime.tsx";
import byTerminal from "./shared/utilities/filterByTerminal.tsx";
import byDepartureTime from "./departures/utilities/sortByDepartureTime.tsx";

export const AppContext = React.createContext({
  selectedDay: '', selectedDate: new Date(), selectedTerminal: ''
});

function App() {
  // State
  const todayDate = new Date(); todayDate.setHours(0, 0, 0, 0);
  const tomorrowDate = new Date(Date.now() + 86400000); tomorrowDate.setHours(0, 0, 0, 0);
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [selectedTerminal, setSelectedTerminal] = useState('T5');
  const host = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://kollaflygetnu-api.azurewebsites.net';
  const { data: arrivals, isLoading: isLoadingArrivals, error: errorArrivals } = useFetchArrivals(host, 'ARN', DateFormatter.toLocalDateString(selectedDate));
  const { data: departures, isLoading: isLoadingDepartures, error: errorDepartures } = useFetchDepartures(host, 'ARN', DateFormatter.toLocalDateString(selectedDate));
  const [initiating, setInitiating] = useState(true);
  const [initiated, setInitiated] = useState(false);
  const hasArrivals = arrivals && arrivals.length > 0;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showArrivals, setShowArrivals] = useState(true);
  const [showDepartures, setShowDepartures] = useState(false);

  // Event handlers
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  }

  // Effects
  useEffect(() => {
    if (hasArrivals) {
      setInitiating(false);
      setInitiated(true);
    }

    if (errorArrivals) {
      setInitiating(false);
    }
  }, [errorArrivals, arrivals, hasArrivals]);

  return (
    <>
      <div className='flex flex-col lg:w-1/3 mx-auto h-dvh'>
        {(errorArrivals || errorDepartures) && <div className="flex flex-grow justify-center items-center text-primary"><h1>Något gick fel</h1></div>}
        {initiating && <div className="flex flex-grow justify-center items-center text-primary"><h1>Laddar...</h1></div>}
        {
          initiated && showArrivals &&
          <>
            <div className="flex items-center justify-center text-2xl text-gray-400 my-1">
              <h1>Ankommande {selectedDate.toUTCString() === todayDate.toUTCString() ? 'idag' : 'imorgon'}</h1>
            </div>
            <FlightList source={arrivals.sort(byArrivalTime).filter(byTerminal(selectedTerminal))} isLoading={isLoadingArrivals}>
              {
                (flight: IArrival) => {
                  return (<ArrivalCard data={flight} />);
                }
              }
            </FlightList>
          </>
        }
        {
          initiated && showDepartures &&
          <>
            <div className="flex items-center justify-center text-2xl text-gray-400 my-1">
              <h1>Avgående {selectedDate.toUTCString() === todayDate.toUTCString() ? 'idag' : 'imorgon'}</h1>
            </div>
            <FlightList source={departures.sort(byDepartureTime).filter(byTerminal(selectedTerminal))} isLoading={isLoadingDepartures}>
              {
                (flight: IDeparture) => {
                  return (<DepartureCard data={flight} />);
                }
              }
            </FlightList>
          </>
        }
        {
          initiated && arrivals && <div className="flex justify-end">
            <div className="rounded-lg bg-gray-200 p-2 m-2" onClick={toggleDrawer(true)}>
              <MdMenu className="text-2xl"></MdMenu>
            </div>
          </div>
        }
      </div >
      <Drawer open={drawerOpen} anchor="bottom" onClose={toggleDrawer(false)}>
        <div className="flex flex-grow lg:w-1/3 lg:mx-auto">
          <div className="w-full">
            <div className="flex gap-1 py-2 px-1">
              <Button size="lg" variant={showArrivals ? 'solid' : 'outlined'} onClick={() => { setShowDepartures(false); setShowArrivals(true); }} fullWidth={true}>Ankommande</Button>
              <Button size="lg" variant={showDepartures ? 'solid' : 'outlined'} onClick={() => { setShowArrivals(false); setShowDepartures(true); }} fullWidth={true}>Avgående</Button>
            </div>
            <div className="flex gap-1 py-2 px-1">
              <Button size="lg" variant={selectedDate.toUTCString() === todayDate.toUTCString() ? 'solid' : 'outlined'} onClick={() => setSelectedDate(todayDate)} fullWidth={true}>Idag</Button>
              <Button size="lg" variant={selectedDate.toUTCString() === tomorrowDate.toUTCString() ? 'solid' : 'outlined'} onClick={() => setSelectedDate(tomorrowDate)} fullWidth={true}>Imorgon</Button>
            </div>
            <div className="px-1 text-primary">
              <h2>Terminal:</h2>
            </div>
            <div className="flex gap-1 py-2 px-1">
              <Button size="lg" variant={selectedTerminal === 'T1' ? "solid" : "outlined"} onClick={() => setSelectedTerminal('T1')}>1</Button>
              <Button size="lg" variant={selectedTerminal === 'T2' ? "solid" : "outlined"} onClick={() => setSelectedTerminal('T2')}>2</Button>
              <Button size="lg" variant={selectedTerminal === 'T3' ? "solid" : "outlined"} onClick={() => setSelectedTerminal('T3')}>3</Button>
              <Button size="lg" variant={selectedTerminal === 'T4' ? "solid" : "outlined"} onClick={() => setSelectedTerminal('T4')}>4</Button>
              <Button size="lg" variant={selectedTerminal === 'T5' ? "solid" : "outlined"} onClick={() => setSelectedTerminal('T5')}>5</Button>
            </div>
          </div>
        </div>
        <div className="flex justify-end lg:w-1/3 lg:mx-auto">
          <div className="rounded-full bg-gray-200 p-1 m-2" onClick={toggleDrawer(false)}>
            <MdClose size={"2rem"} />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default App;