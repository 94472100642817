export default function getAirlineName(iataCode) {
    switch (iataCode) {
        case "A3":
            return "Aegean Airlines";
        case "AA":
            return "American Airlines";
        case "AC":
            return "Air Canada";
        case "AF":
            return "Air France";
        case "AI":
            return "Air India";
        case "AK":
            return "Air Asia";
        case "AY":
            return "Finnair";
        case "BA":
            return "British Airways";
        case "BIX":
            return "Braathens Int Airways";
        case "BLX":
            return "TUI";
        case "CA":
            return "Air China";
        case "D8":
        case "DY":
            return "Norwegian";
        case "EK":
            return "Emirates";
        case "EW":
            return "Eurowings";
        case "FI":
            return "Icelandair";
        case "IB":
            return "Iberia";
        case "JU":
            return "Air Serbia";
        case "ET":
            return "Ethiopian Airlines";
        case "EY":
            return "Etihad Airways";
        case "FR":
            return "Ryanair";
        case "HP":
            return "PopulAir";
        case "JL":
            return "Japan Airlines";
        case "KL":
            return "KLM";
        case "LH":
            return "Lufthansa";
        case "LO":
            return "LOT Polish Airlines";
        case "LX":
            return "Swiss International Air Lines";
        case "NH":
            return "All Nippon Airways";
        case "OS":
            return "Austrian Airlines";
        case "PC":
            return "Pegasus Airlines";
        case "QR":
            return "Qatar Airways";
        case "SK":
            return "SAS Scandinavian Airlines";
        case "SQ":
            return "Singapore Airlines";
        case "TK":
            return "Turkish Airlines";
        case "TP":
            return "TAP Portugal";
        case "UA":
            return "United Airlines";
        case "VF":
            return "AJet";
        case "W6":
            return "Wizz Air";
        case "U2":
            return "EasyJet";
        default:
            return "Unknown airline";
    }
}