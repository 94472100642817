import { useState, useEffect } from "react";
import IArrival from "../interfaces/IArrival.ts";

export default function useFetchArrivals(
  host: string,
  airport: string,
  date: string
): {
  data: IArrival[];
  isLoading: boolean;
  error: string | null;
} {
  const [data, setData] = useState<IArrival[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    setError(null);

    const endpoint = `${host}/api/arrivals/${airport}/${date}`;
    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          console.log(
            `${new Date().toLocaleTimeString()}: Error fetching data`
          );
          console.log(
            `${new Date().toLocaleTimeString()}: ${endpoint}: ${
              response.statusText
            }`
          );
          setError(response.statusText);
        }

        return response.json();
      })
      .then((data: any) => {
        setData(data);
      })
      .catch((reason) => {
        setError(reason);
        console.log(`${new Date().toLocaleTimeString()}: Error fetching data`);
        console.log(
          `${new Date().toLocaleTimeString()}: ${endpoint}: ${reason}`
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [airport, date, host]);

  return { data, isLoading, error };
}
